import { Nav } from "./components/nav";
import { Hero } from "./components/hero";
import { Intro } from "./components/intro";
import { Upsell } from "./components/upsell";
import { Contact } from "./components/contact";

function App() {
  return (
    <div className="App">
      <Nav />
      <Hero />
      <Intro />
      <Upsell />
      <Contact />
    </div>
  );
}

export default App;
