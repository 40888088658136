export const Contact = () => {
  return (
    <div class="mx-auto max-w-7xl px-6 lg:px-8 mt-24 sm:mt-32 lg:mt-40">
      <div class="mx-auto max-w-2xl lg:max-w-none">
        <div class="-mx-6 rounded-4xl bg-neutral-950 px-6 py-10 sm:mx-0 sm:py-16 md:px-12">
          <div class="mx-auto max-w-4xl">
            <div class="max-w-xl">
              <h2 class="font-display text-3xl font-medium text-white [text-wrap:balance] sm:text-4xl">
                Tell us about your project
              </h2>
              <div class="mt-6 flex">
                <a
                  class="inline-flex rounded-full px-4 py-1.5 text-sm font-semibold transition bg-white text-neutral-950 hover:bg-neutral-200"
                  href="mailto:admin@quantumcodecorp.com"
                >
                  <span class="relative top-px">Say Hej</span>
                </a>
              </div>
              <div class="mt-10 border-t border-white/10 pt-10">
                <h3 class="font-display text-base font-semibold text-white">
                  Our office
                </h3>
                <ul
                  role="list"
                  class="mt-6 grid grid-cols-1 gap-8 sm:grid-cols-2"
                >
                  <li>
                    <address class="text-sm not-italic text-neutral-300">
                      <strong class="text-white">Toronto, Ontario.</strong>
                    </address>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
