export const Nav = () => {
  return (
    <nav
      class="flex items-center justify-between p-6 lg:px-8"
      aria-label="Global"
    >
      <div class="flex lg:flex-1">
        <a href="#" class="-m-1.5 p-1.5">
          <span class="sr-only">QuantumCode Corp.</span>
          <img class="h-8 w-auto" src="icons/logo.png" alt="" />
        </a>
      </div>
    </nav>
  );
};
