export const Upsell = () => {
  return (
    <section
      id="get-started-today"
      class="relative mx-auto max-w-5xl overflow-hidden py-32 px-8"
    >
      <span class="block font-display tracking-tight [text-wrap:balance] text-4xl font-medium sm:text-5xl text-neutral-950">
        We help you identify, explore and respond to new opportunities.
      </span>

      <div class="lg:flex lg:items-center lg:justify-end py-24">
        <div class="justify-center lg:justify-end relative flex aspect-[719/680] w-full">
          <svg viewBox="0 0 655 680" fill="none" class="h-full">
            <g clip-path="url(#:S1:-clip)" class="group">
              <g class="origin-center scale-100 transition duration-500 motion-safe:group-hover:scale-105">
                <foreignObject width="655" height="680">
                  <img
                    alt=""
                    loading="lazy"
                    width="2400"
                    height="3000"
                    decoding="async"
                    data-nimg="1"
                    src="icons/laptop.webp"
                  />
                </foreignObject>
              </g>
              <use
                href="#:S1:-shape"
                stroke-width="2"
                class="stroke-neutral-950/10"
              ></use>
            </g>
            <defs>
              <clipPath id=":S1:-clip">
                <path
                  id=":S1:-shape"
                  d="M537.827 9.245A11.5 11.5 0 0 1 549.104 0h63.366c7.257 0 12.7 6.64 11.277 13.755l-25.6 128A11.5 11.5 0 0 1 586.87 151h-28.275a15.999 15.999 0 0 0-15.689 12.862l-59.4 297c-1.98 9.901 5.592 19.138 15.689 19.138h17.275l.127.001c.85.009 1.701.074 2.549.009 11.329-.874 21.411-7.529 24.88-25.981.002-.012.016-.016.023-.007.008.009.022.005.024-.006l24.754-123.771A11.5 11.5 0 0 1 580.104 321h63.366c7.257 0 12.7 6.639 11.277 13.755l-25.6 128A11.5 11.5 0 0 1 617.87 472H559c-22.866 0-28.984 7.98-31.989 25.931-.004.026-.037.035-.052.014-.015-.02-.048-.013-.053.012l-24.759 123.798A11.5 11.5 0 0 1 490.87 631h-29.132a14.953 14.953 0 0 0-14.664 12.021c-4.3 21.502-23.18 36.979-45.107 36.979H83.502c-29.028 0-50.8-26.557-45.107-55.021l102.4-512C145.096 91.477 163.975 76 185.902 76h318.465c10.136 0 21.179-5.35 23.167-15.288l10.293-51.467Zm-512 160A11.5 11.5 0 0 1 37.104 160h63.366c7.257 0 12.7 6.639 11.277 13.755l-25.6 128A11.5 11.5 0 0 1 74.87 311H11.504c-7.257 0-12.7-6.639-11.277-13.755l25.6-128Z"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                ></path>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div>
          <ul
            role="list"
            class="text-base text-neutral-600 mt-16 lg:mt-0 lg:w-1/2 lg:min-w-[33rem] lg:pl-4"
          >
            <li class="group mt-10 first:mt-0">
              <div>
                <div class="pt-10 group-first:pt-0 group-first:before:hidden group-first:after:hidden relative before:absolute after:absolute before:bg-neutral-950 after:bg-neutral-950/10 before:left-0 before:top-0 before:h-px before:w-6 after:left-8 after:right-0 after:top-0 after:h-px">
                  <strong class="font-semibold text-neutral-950">
                    Web development.{" "}
                  </strong>
                  We specialise in crafting beautiful, high quality websites
                  that helps business grow.
                </div>
              </div>
            </li>
            <li class="group mt-10 first:mt-0">
              <div>
                <div class="pt-10 group-first:pt-0 group-first:before:hidden group-first:after:hidden relative before:absolute after:absolute before:bg-neutral-950 after:bg-neutral-950/10 before:left-0 before:top-0 before:h-px before:w-6 after:left-8 after:right-0 after:top-0 after:h-px">
                  <strong class="font-semibold text-neutral-950">
                    Application development.{" "}
                  </strong>
                  We have a team of skilled developers who are experts in the
                  latest app frameworks.
                </div>
              </div>
            </li>
            <li class="group mt-10 first:mt-0">
              <div>
                <div class="pt-10 group-first:pt-0 group-first:before:hidden group-first:after:hidden relative before:absolute after:absolute before:bg-neutral-950 after:bg-neutral-950/10 before:left-0 before:top-0 before:h-px before:w-6 after:left-8 after:right-0 after:top-0 after:h-px">
                  <strong class="font-semibold text-neutral-950">
                    E-commerce.{" "}
                  </strong>
                  We have ton of experience building e-commerce applications.
                </div>
              </div>
            </li>
            <li class="group mt-10 first:mt-0">
              <div>
                <div class="pt-10 group-first:pt-0 group-first:before:hidden group-first:after:hidden relative before:absolute after:absolute before:bg-neutral-950 after:bg-neutral-950/10 before:left-0 before:top-0 before:h-px before:w-6 after:left-8 after:right-0 after:top-0 after:h-px">
                  <strong class="font-semibold text-neutral-950">
                    Custom content management.{" "}
                  </strong>
                  We are the experts when its comes to custom content management
                  solutions.
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
